<template>
  <transition name="fade">
    <PrimaryModal @onClose="closeModal()" v-show="successActive">
      <div class="success-modal flex flex-column align-items-center">
        <div class="success-icon">
          <svg
            width="5rem"
            height="5rem"
            viewBox="0 0 151 151"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M75.5 0C95.5238 0 114.728 7.95444 128.887 22.1134C143.046 36.2724 151 55.4762 151 75.5C151 95.5238 143.046 114.728 128.887 128.887C114.728 143.046 95.5238 151 75.5 151C55.4762 151 36.2724 143.046 22.1134 128.887C7.95444 114.728 0 95.5238 0 75.5C0 55.4762 7.95444 36.2724 22.1134 22.1134C36.2724 7.95444 55.4762 0 75.5 0ZM66.0949 90.3951L49.3231 73.6125C48.7218 73.0112 48.008 72.5343 47.2224 72.2089C46.4368 71.8835 45.5948 71.716 44.7445 71.716C43.8942 71.716 43.0522 71.8835 42.2666 72.2089C41.4811 72.5343 40.7673 73.0112 40.166 73.6125C38.9517 74.8268 38.2695 76.4738 38.2695 78.191C38.2695 79.9083 38.9517 81.5553 40.166 82.7696L61.5217 104.125C62.1213 104.73 62.8345 105.209 63.6204 105.536C64.4062 105.864 65.249 106.032 66.1002 106.032C66.9515 106.032 67.7943 105.864 68.5801 105.536C69.366 105.209 70.0792 104.73 70.6788 104.125L114.9 59.8931C115.51 59.2943 115.994 58.5808 116.326 57.7938C116.659 57.0068 116.832 56.1617 116.836 55.3075C116.84 54.4532 116.674 53.6066 116.35 52.8166C116.025 52.0265 115.547 51.3085 114.943 50.7041C114.339 50.0997 113.622 49.6208 112.832 49.295C112.042 48.9692 111.196 48.803 110.342 48.806C109.488 48.809 108.642 48.981 107.855 49.3123C107.067 49.6435 106.353 50.1274 105.754 50.736L66.0949 90.3951Z"
              fill="var(--primary-4)"
            />
          </svg>
        </div>
        <h2 class="success-modal__title headline-4 w700">
          Заявка успешно отправлена
        </h2>
        <p class="success-modal__message headline-5">
          В течение дня мы вам перезвоним.
        </p>
        <button
          class="success-modal__button primary-button"
          @click="closeModal()"
        >
          Закрыть
        </button>
      </div>
    </PrimaryModal>
  </transition>
</template>
<script setup>
import PrimaryModal from "./PrimaryModal.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

const successActive = computed(() => store.getters.successModal);

const closeModal = () => {
  store.commit("setSuccessModal", false);
};
</script>

<style lang="scss" scoped>
.success-modal {
  text-align: center;
  min-width: 20rem;

  &__title {
    margin-bottom: 1rem;
    color: var(--primary-2);
  }

  &__subtitle {
    margin-bottom: 3rem;
  }

  &__button {
    margin-top: 0.63rem;
  }

  &__message {
    margin-bottom: 1.25rem;
  }

  .success-icon {
    margin-bottom: 20px;
  }
}
</style>
